import React, { useState, useEffect } from 'react';
import { Box, Container, Button, Typography } from '@mui/material';
import {DataGrid, GridOverlay, GridToolbar} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteDialog from "../common/DeleteDialog";
import VPNHostDialog from './VPNHostDialog';
import VPNHostDownloadLinkDialog from './VPNHostDownloadLinkDialog';
import { useSnackbar } from "notistack";
import { getVpnInstances, getHosts, removeHost} from '../../services/vpnService';
import {useParams} from "react-router-dom";
import CustomNoRowsOverlay from "../common/CustomNoRowsOverlay";

const VPNHosts = () => {
    const [vpnHosts, setVpnHosts] = useState([]);
    const [vpnInstances, setVpnInstances] = useState([]);
    const [hostDialogOpen, setHostDialogOpen] = useState({show: false, host: null, instances: null});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState({show: false, host: null});
    const [downloadDialogOpen, setDownloadDialogOpen] = useState({show: false, host: null});
    const [loadingHosts, setLoadingHosts] = useState(false)

    const {id} = useParams()

    const {enqueueSnackbar} = useSnackbar();

    const columns = [
        {field: 'id', headerName: 'ID', minWidth: 50, flex: 1},
        {field: 'host', headerName: 'Host', minWidth: 150, flex: 1},
        {field: 'lighthouse', headerName: 'Lighthouse', minWidth: 100, flex: 1},
        {field: 'ip_vpn', headerName: 'VPN IP', minWidth: 150, flex: 1},
        {field: 'ip_public', headerName: 'Public IP', minWidth: 150, flex: 1},
        {field: 'use_relay', headerName: 'Use Relay', minWidth: 100, flex: 1},
        {field: 'is_relay', headerName: 'Is Relay', minWidth: 100, flex: 1},
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Button onClick={() => handleEdit(params.row)}><EditIcon/></Button>
                        <Button onClick={() => handleDelete(params.row)}><DeleteIcon/></Button>
                        <Button onClick={() => handleDownload(params.row)}><CloudDownloadIcon/></Button>
                    </>
                )
            }
        },
    ];

    const reload = () => {
        if (id) {
            setLoadingHosts(true)
            getHosts(enqueueSnackbar, id, hosts => {
                setVpnHosts(hosts.sort((a, b) => a.id - b.id))
                setLoadingHosts(false)
            }, () => setLoadingHosts(false));
        }
    };

    const handleDeleteHost = (host) => {
        removeHost(enqueueSnackbar, host.vpn_instance_id, host.id, () => reload())
    };

    const handleDelete = (rowData) => {
        setDeleteDialogOpen({show: true, host: rowData});
    };

    const handleDeleteClose = () => {
        setDeleteDialogOpen({show: false, host: null});
    };

    const handleEdit = (rowData) => {
        setHostDialogOpen({show: true, host: rowData, instances: vpnInstances});
    };

    const handleDownload = (rowData) => {
        setDownloadDialogOpen({show: true, host: rowData});
    };

    const handleDownloadClose = (rowData) => {
        setDownloadDialogOpen({show: false, host: null});
    };

    const handleEditClose = () => {
        setHostDialogOpen({show: false, host: null, instances: vpnInstances});
        reload();
    };

    useEffect(() => {
        reload()
    }, [id]);

    useEffect(() => {
        getVpnInstances(enqueueSnackbar, instances => setVpnInstances(instances))
    }, [])

    return (
        <Container id="host-page" maxWidth="xl">
            <Box className="box-main">
                <Box sx={{ display: 'flex'}}>
                    <Typography variant="h6">Host:</Typography>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', pl: 1 }}>{vpnInstances.filter(i => +i.id === +id)[0]?.name}</Typography>
                </Box>
                <Button variant="contained" color="primary" startIcon={<AddIcon/>}
                        onClick={() => setHostDialogOpen({show: true, host: null, instances: vpnInstances})}>
                    Add New VPN Host
                </Button>
            </Box>
            <Box className={`box-list ${loadingHosts && vpnHosts?.length === 0 ? 'loading-screen' : ''} ${vpnHosts?.length > 0 ? '' : 'empty-table'}`}>
                <DataGrid
                    rows={vpnHosts}
                    loading={loadingHosts}
                    slots={{toolbar: GridToolbar, noRowsOverlay: () => <CustomNoRowsOverlay message={'No hosts available'}/>}}
                    className="box-list-table"
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 25},
                        },
                    }}
                    pageSizeOptions={[5, 10, 25, 100]}
                />
            </Box>

            {hostDialogOpen?.show && <VPNHostDialog
                onClose={handleEditClose}
                host={hostDialogOpen.host}
                instances={hostDialogOpen.instances}
                homeInstanceId={id}
            />}
            {deleteDialogOpen?.show && <DeleteDialog
                onClose={() => handleDeleteClose()}
                message={`Are you sure you want to delete the VPN Host ${deleteDialogOpen?.host?.host}?`}
                onDelete={() => handleDeleteHost(deleteDialogOpen?.host)}
            />}
            {downloadDialogOpen?.show && <VPNHostDownloadLinkDialog
                onClose={() => handleDownloadClose()}
                instanceId={downloadDialogOpen.host.vpn_instance_id}
                hostId={downloadDialogOpen.host.id}
            />}
        </Container>
    );
};

export default VPNHosts;
