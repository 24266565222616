import { Routes, Route, Navigate } from "react-router-dom";
import VPNInstancePage from '../vpn/VPNInstancePage';
import VPNHosts from "../vpn_host/VPNHosts";
import Home from "../home/Home";
import Layout from "../common/Layout";
import RSAKeyListPage from "../rsa_key/RsaKeyListPage"
import SystemUserList  from "../system_user/SystemUserListPage"
import ProxyDomains from "../domain/ProxyDomains"
import ProxyHosts from "../proxy_host/ProxyHosts"

const DefaultContainer = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/vpn" element={<VPNInstancePage />} />
                <Route path="/hosts/:id" element={<VPNHosts />} />
                <Route path="/rsa-keys" element={<RSAKeyListPage />} />
                <Route path="/system-users" element={<SystemUserList />} />
                <Route path="/proxy-domains" element={<ProxyDomains />} />
                <Route path="/proxy-hosts" element={<ProxyHosts />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Layout>
    );
}

export default DefaultContainer;