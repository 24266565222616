import { BrowserRouter as Router} from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { login } from './store/authSlice';
import { ThemeProvider, CssBaseline } from '@mui/material';
import darkTheme from './themes/defaulTheme'
import AuthContainer from './components/containers/AuthContainer';
import DefaultContainer from './components/containers/DefaultContainer';
import {SnackbarProvider} from "notistack";

const App = () => {
  const { isLoggedIn } = useSelector(state => state.auth);
  const dispatch = useDispatch()
  useEffect(() => {
    const token = localStorage.getItem('user');
    if (token) {
      dispatch(login())
    }

  }, []);

  return (
    <Router>
      <SnackbarProvider maxSnack={5}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          {isLoggedIn ? <DefaultContainer /> : <AuthContainer />}
        </ThemeProvider>
      </SnackbarProvider>
    </Router>
  );
};

export default App;
