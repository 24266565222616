import React, {useEffect, useState} from 'react';
import {Button, Container, Box, Typography} from '@mui/material';
import {useSnackbar} from "notistack";
import {deleteProxyDomain, getProxyDomains, saveProxyDomain} from '../../services/proxyDomain.service';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import ProxyDomainDialog from './ProxyDomainDialog';
import AddIcon from "@mui/icons-material/Add";
import CustomNoRowsOverlay from "../common/CustomNoRowsOverlay";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../common/DeleteDialog";

const ProxyDomains = () => {
    const [domains, setDomains] = useState([]);
    const [domainDialogOpen, setDomainDialogOpen] = useState({show: false, domain: null});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState({show: false, domain: null});
    const [loadingDomains, setLoadingDomains] = useState(false);

    const {enqueueSnackbar} = useSnackbar();

    const columns = [
        {field: 'id', headerName: 'ID', width: 50},
        {field: 'domain', headerName: 'Domain', minWidth: 150, flex: 1},
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Button onClick={() => handleEdit(params.row)}><EditIcon/></Button>
                        <Button onClick={() => handleDelete(params.row)}><DeleteIcon/></Button>
                    </>
                )
            }
        },
    ];

    const reload = () => {
        setLoadingDomains(true)
        getProxyDomains(enqueueSnackbar, res => {
            setDomains(res.sort((a, b) => a.id - b.id))
            setLoadingDomains(false)
        }, () => setLoadingDomains(false))
    }

    const handleEdit = (rowData) => {
        setDomainDialogOpen({show: true, domain: rowData});
    };

    const handleDeleteHost = (domain) => {
        deleteProxyDomain(enqueueSnackbar, domain.id, () => reload())
    };

    const handleDomainDialogOpen = () => {
        setDomainDialogOpen({show: true, domain: null});
    };

    const handleDomainDialogClose = () => {
        setDomainDialogOpen({show: false, domain: null});
        reload();
    };

    const handleDeleteClose = () => {
        setDeleteDialogOpen({show: false, domain: null});
    };

    const handleDelete = (rowData) => {
        setDeleteDialogOpen({show: true, domain: rowData});
    };

    useEffect(() => {
        reload()
    }, []);

    return (
        <Container id="domains-page" maxWidth="xl">
            <Box className="box-main">
                <Typography variant="h5">Proxy Domains</Typography>
                <Button variant="contained" color="primary" startIcon={<AddIcon/>}
                        onClick={handleDomainDialogOpen}>
                    Add new Proxy Domain
                </Button>
            </Box>
            <Box className={`box-list ${loadingDomains && domains?.length === 0 ? 'loading-screen' : ''} ${domains?.length > 0 ? '' : 'empty-table'}`}>
                <DataGrid
                    rows={domains}
                    loading={loadingDomains}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: () => <CustomNoRowsOverlay message={'No Proxy Domains available'}/>
                    }}
                    className="box-list-table"
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 5},
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
                {domainDialogOpen?.show &&
                    <ProxyDomainDialog
                        onClose={handleDomainDialogClose}
                        onSave={saveProxyDomain}
                        domain={domainDialogOpen.domain}
                    />
                }
                {deleteDialogOpen?.show && <DeleteDialog
                    onClose={() => handleDeleteClose()}
                    message={`Are you sure you want to delete the Domain: ${deleteDialogOpen?.domain?.domain}?`}
                    onDelete={() => handleDeleteHost(deleteDialogOpen?.domain)}
                />}
            </Box>
        </Container>
    );
};

export default ProxyDomains;
