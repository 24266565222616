import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import LoginPage from '../auth/LoginPage';
import RegisterPage from '../auth/RegisterPage';


const AuthContainer = () => {
    return (
        <Routes>
            <Route path='/' element={ <Navigate to="/signin" /> }/>
            <Route path="/signin" element={ <LoginPage/>} />
            <Route path="/signup" element={ <RegisterPage/>} />
            <Route path="*" element={ <Navigate to="/"/>} />
        </Routes>
    );
}

export default AuthContainer;