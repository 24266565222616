import {styled, Tooltip, tooltipClasses} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useRef } from 'react';
import {TooltipProps} from "@mui/material";

const RenderCellTooltip = (props: GridRenderCellParams) => {
    const { value } = props;

    const textElementRef = useRef<HTMLSpanElement | null>(null);

    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 300,
            maxHeight: 200,
            overflow: 'auto',
        },
    });

    return (
        <CustomTooltip title={value}>
      <span
          ref={textElementRef}
          style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
          }}
      >
        {value}
      </span>
        </CustomTooltip>
    );
};

export default RenderCellTooltip;