import {Box, Button, List, ListItemButton, ListItemText, Divider, Collapse, Tooltip, Zoom} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NoteIcon from '@mui/icons-material/Note';
import LogoutIcon from '@mui/icons-material/Logout';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import ComputerIcon from '@mui/icons-material/Computer';
import { NavLink } from "react-router-dom";
import { useNavigate  } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import {logout} from '../../actions/auth';
import "@fontsource/source-sans-pro";
import './Sidebar.scss'
import {useEffect, useState} from "react";
import {ExpandLess, ExpandMore, LaptopMac} from "@mui/icons-material";
import {getVpnInstances} from "../../services/vpnService";
import {useSnackbar} from "notistack";

const Sidebar = () => {
  const [openHost, setOpenHost] = useState(false)
  const [vpnInstances, setVpnInstances] = useState([])

  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const drawerWidth = 240;

  const handleLogout = () => {
    dispatch(logout());
    navigate('/signin');
  };

  const showHostsList = (e) => {
    e.stopPropagation()
    if (vpnInstances && vpnInstances.length > 0) {
      setOpenHost(!openHost)
    }
  }

  useEffect(() => {
    getVpnInstances(enqueueSnackbar, instances => {
      setVpnInstances(instances)
    })

    if (window.location.pathname?.includes('/hosts/')) {setOpenHost(true)}
  }, [])

  return (
      <Box id="sidebar">
        <CssBaseline/>
        <Drawer className="menu-background"
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                  },
                }}
                variant="permanent"
                anchor="left"
        >
          <Toolbar>
            <Grid container textAlign="center">
              <Grid item xs={4}>
                <Button disableRipple component={NavLink} to="/user">
                  <AccountCircleIcon/>
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button disableRipple component={NavLink} to="/notes">
                  <NoteIcon/>
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button disableRipple component={NavLink} onClick={handleLogout} to="/signout">
                  <LogoutIcon/>
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider/>
          <List>
            <Box className="menu-box">
              <ListItemButton disableRipple component={NavLink} to="/vpn"
                              selected={window.location.pathname?.includes('/vpn')}>
                <ListItemIcon>
                  <VpnLockIcon/>
                </ListItemIcon>
                <ListItemText primary="VPN"/>
              </ListItemButton>

              <Tooltip
                  title="First create VPN instance"
                  TransitionComponent={Zoom}
                  followCursor
                  arrow
                  disableHoverListener={vpnInstances && vpnInstances.length > 0}
              >
                <div>
                  <ListItemButton
                      disableRipple
                      component="div"
                      disabled={!(vpnInstances && vpnInstances.length > 0)}
                      onClick={showHostsList}
                  >
                    <ListItemIcon>
                      <ComputerIcon color={openHost ? "primary" : ""}/>
                    </ListItemIcon>
                    <ListItemText primary="Hosts"/>
                    {vpnInstances && vpnInstances.length > 0 ? openHost ? <ExpandLess/> : <ExpandMore/> : null}
                  </ListItemButton>
                </div>
              </Tooltip>
              <Collapse in={openHost} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{
                  width: '100%',
                  boxShadow: (theme) => `inset 0px 0px 5px 0px ${theme.palette.primary.main}`,
                  borderRadius: 1,
                  scrollbarWidth: "none", // Hide the scrollbar for firefox
                  '&::-webkit-scrollbar': {
                    display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                  },
                  '&-ms-overflow-style:': {
                    display: 'none', // Hide the scrollbar for IE
                  },
                  overflow: 'auto',
                  maxHeight: 300,
                  '& ul': {padding: 0},
                }}>
                  {vpnInstances && vpnInstances.map(instance => {
                    return <ListItemButton
                        sx={{pl: 4}}
                        selected={window.location.pathname?.endsWith(`/hosts/${instance.id}`)}
                        component={NavLink}
                        to={`/hosts/${instance.id}`}
                        key={instance.id}
                    >
                      <ListItemIcon>
                        <LaptopMac/>
                      </ListItemIcon>
                      <ListItemText primary={instance.name}/>
                    </ListItemButton>
                  })}
                </List>
              </Collapse>

              <ListItemButton disableRipple component={NavLink} to="/rsa-keys"
                              selected={window.location.pathname?.includes('/rsa-keys')}>
                <ListItemIcon>
                  <KeyIcon/>
                </ListItemIcon>
                <ListItemText primary="RSA Keys"/>
              </ListItemButton>

              <ListItemButton disableRipple component={NavLink} to="/system-users"
                              selected={window.location.pathname?.includes('/system-users')}>
                <ListItemIcon>
                  <PersonIcon/>
                </ListItemIcon>
                <ListItemText primary="System Users"/>
              </ListItemButton>

              <Divider/>

              <ListItemButton disableRipple component={NavLink} to="/proxy-domains"
                              selected={window.location.pathname?.includes('/proxy-domains')}>
                <ListItemIcon>
                  <HttpsIcon/>
                </ListItemIcon>
                <ListItemText primary="Proxy Domains"/>
              </ListItemButton>
              <ListItemButton disableRipple component={NavLink} to="/proxy-hosts"
                              selected={window.location.pathname?.includes('/proxy-hosts')}>
                <ListItemIcon>
                  <HttpsIcon/>
                </ListItemIcon>
                <ListItemText primary="Proxy Hosts"/>
              </ListItemButton>
            </Box>
          </List>
          <Divider/>
        </Drawer>
      </Box>
  );
};

export default Sidebar;
