import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, TextField } from '@mui/material';
import { getPermanentInstallLink, getPermanentUninstallLink } from '../../services/vpnService'; // Import the uninstall function
import { useSnackbar } from 'notistack';

const VPNHostDownloadLinkDialog = ({ onClose, instanceId, hostId }) => {
  const [link, setLink] = useState('');
  const [isInstallLink, setIsInstallLink] = useState(true); // Track whether the link is for installation

  const { enqueueSnackbar } = useSnackbar();

  const handleGenerate = (generateFunction) => {
    const tokenData = {
      instance_id: instanceId,
      host_id: hostId,
      permament: true,
    };
    generateFunction(enqueueSnackbar, tokenData, (res) => setLink(`${window.location.origin}/api/vpn/token/${res?.token}`));
    setIsInstallLink(true); // Reset to install link after generating
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Download Confirmation</DialogTitle>
      <DialogContent>
        <Typography>Do you want to generate a permanent link?</Typography>
        {link && (
          <Box my={2}>
            <TextField value={link} fullWidth InputProps={{ readOnly: true }} onClick={(e) => e.target.select()} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {isInstallLink ? ( // Display the appropriate button and link based on the state
          <>
            <Button onClick={() => handleGenerate(getPermanentInstallLink)} color="primary">
              Generate Install Link
            </Button>
            <Button onClick={() => setIsInstallLink(false)}>Switch to Uninstall Link</Button>
          </>
        ) : (
          <>
            <Button onClick={() => handleGenerate(getPermanentUninstallLink)} color="primary">
              Generate Uninstall Link
            </Button>
            <Button onClick={() => setIsInstallLink(true)}>Switch to Install Link</Button>
          </>
        )}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default VPNHostDownloadLinkDialog;
