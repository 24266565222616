import axios from 'axios';
import apiHandler from "./ApiHandler";

export const getProxyDomains = (enqueueSnackbar, callback, errorCallback) => {
  const config = {
    url: '/api/reverse-proxy/domain',
  }
  apiHandler("Fetching Proxy Domains", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, false, true, errorCallback)
}

export const getProxyDomain = (id, callback) => {
  const config = {
    url: `/api/reverse-proxy/domain/${id}`,
  }
  apiHandler("", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, null, false, false)
}

export const saveProxyDomain = (enqueueSnackbar, data, callback, errorCallback) => {
  const config = {
    url: data?.id ? `/api/reverse-proxy/domain/${data.id}` : '/api/reverse-proxy/domain',
    method: data?.id ? 'POST' : 'PUT',
    data: data
  }
  apiHandler("Save Proxy Domain", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, true, false, errorCallback)
}

export const deleteProxyDomain = (enqueueSnackbar, id, callback) => {
  const config = {
  }
  apiHandler("Deleting Proxy Domain", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar)
}
