import axios from 'axios';
import apiHandler from "./ApiHandler";

export const getSystemUsers = (enqueueSnackbar, callback, errorCallback) => {
  const config = {
    url: '/api/system-user',
  }
  apiHandler("Getting System Users", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, false, false, errorCallback)
}

export const getSystemUser = (enqueueSnackbar, id, callback) => {
  const config = {
    url: `/api/system-user/${id}`,
  }
  apiHandler("Getting System User", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar)
}

export const saveSystemUser = (enqueueSnackbar, userData, callback, errorCallback) => {
  const config = {
    url: userData?.id ? `/api/system-user/${userData.id}` : '/api/system-user',
    method: userData?.id ? 'POST' : 'PUT',
    data: userData
  }
  apiHandler("Save System User", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, true, false, errorCallback)
}

export const deleteSystemUser = (enqueueSnackbar, id, callback) => {
  const config = {
    url: `/api/system-user/${id}`,
    method: 'DELETE'
  }
  apiHandler("Deleting System User", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar)
}