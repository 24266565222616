import {GridOverlay} from "@mui/x-data-grid";
import {Grid, IconButton, Typography} from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import React from "react";

const CustomNoRowsOverlay = ({message}) => {
    return (
        <GridOverlay>
            <Grid container direction="column" alignItems="center">
                <Typography variant="h6" color="textSecondary">
                    {message}
                </Typography>
                <IconButton>
                    <DangerousIcon fontSize="large"/>
                </IconButton>
            </Grid>
        </GridOverlay>
    );
}

export default CustomNoRowsOverlay;