import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  FormControl,
  Switch,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  FormHelperText,
  Alert
} from '@mui/material';
import {getFreeIPs,  saveHost} from '../../services/vpnService';
import {useSnackbar} from "notistack";
import * as yup from "yup";
import {useFormik} from "formik";
import {AlertTitle} from "@mui/lab";

const VPNHostDialog = ({onClose, host, instances, homeInstanceId}) => {
  const [availableIps, setAvailableIps] = useState([]);
  const [loadingIPs, setLoadingIPs] = useState(false)
  const [error, setError] = useState("")

  const {enqueueSnackbar} = useSnackbar();

  const validationSchema = yup.object({
    host: yup
        .string()
        .required('Host name is required'),
    vpn_instance_id: yup
        .string()
        .required('Instance is required'),
    ip_vpn: yup
        .string()
        .required('The IP is required')
  });

  const formik = useFormik({
    initialValues: {
      id: null,
      host: "",
      vpn_instance_id: "",
      ip_vpn: "",
      ip_public: "",
      is_relay: false,
      lighthouse: false,
      use_relay: false
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveHost(enqueueSnackbar, values.vpn_instance_id, values.id, values, () => {
        onClose()
      }, (res) => setError('Data save failed: ' + (res?.response?.data?.error || res?.message)))
    },
    validateOnChange: !!host?.id,
    validateOnMount: false
  });

  useEffect(() => {
    if (formik.values?.vpn_instance_id) {
      setLoadingIPs(true)
      getFreeIPs(enqueueSnackbar, formik.values?.vpn_instance_id, data => {
        const newHost = (host?.ip_vpn && +homeInstanceId === +formik.values?.vpn_instance_id) ? data.data.concat(host?.ip_vpn) : data.data
        setAvailableIps(newHost)
        setLoadingIPs(false)
      }, () => setLoadingIPs(false))
    }
  }, [formik.values?.vpn_instance_id])

  useEffect(() => {
    if (host) {
      formik.setValues({
        id: host.id,
        host: host.host,
        vpn_instance_id: host.vpn_instance_id,
        ip_vpn: host.ip_vpn,
        ip_public: host.ip_public,
        is_relay: host.is_relay,
        lighthouse: host.lighthouse,
        use_relay: host.use_relay
      })
    }
  }, []);

  return (
      <Dialog
          open={true}
          onClose={onClose}
          fullWidth
          maxWidth="sm"
      >
        <DialogTitle>{formik.values?.id ? 'Edit' : 'Add'} VPN Host</DialogTitle>
        {error &&
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
        }
        <DialogContent>
          <Box mb={2}>
            <TextField
                variant="standard"
                name="host"
                label="Host Name"
                value={formik.values?.host}
                onChange={formik.handleChange}
                fullWidth
                helperText={formik.errors?.host}
            />
          </Box>
          <Box mb={2}>
            <FormControl fullWidth variant="standard">
              <InputLabel>VPN Instance</InputLabel>
              <Select
                  name="vpn_instance_id"
                  value={formik.values?.vpn_instance_id}
                  onChange={formik.handleChange}
                  MenuProps={{PaperProps: {sx: {maxHeight: 300}}}}
                  fullWidth
              >
                {instances && instances.map(i => (
                    <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.errors?.vpn_instance_id}</FormHelperText>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl fullWidth variant="standard">
              <InputLabel>Available IPs</InputLabel>
              <Select
                  name="ip_vpn"
                  disabled={loadingIPs}
                  value={loadingIPs ? 1 : formik.values?.ip_vpn}
                  onChange={formik.handleChange}
                  MenuProps={{PaperProps: {sx: {maxHeight: 300}}}}
                  fullWidth
              >
                {loadingIPs ?
                    <MenuItem key={1} value={1}><CircularProgress size={15}/><Typography variant='span' sx={{pl: 1}}>Loading...</Typography></MenuItem>
                    :
                    availableIps.map((ip, idx) => (
                        <MenuItem key={idx} value={ip}>{ip}</MenuItem>
                    ))
                }
              </Select>
              <FormHelperText>{formik.errors?.ip_vpn}</FormHelperText>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControlLabel
                control={<Switch checked={formik.values?.is_relay} onChange={formik.handleChange} name="is_relay"/>}
                label="Is Relay?"/>
          </Box>
          <Box mb={2}>
            <FormControlLabel
                control={<Switch checked={formik.values?.lighthouse} onChange={formik.handleChange} name="lighthouse"/>}
                label="Lighthouse?"/>
          </Box>
          <Box mb={2}>
            <TextField
                variant="standard"
                disabled={!formik.values?.lighthouse}
                name="ip_public"
                label="Public IP"
                value={formik.values?.ip_public}
                onChange={formik.handleChange}
                fullWidth
                helperText={formik.errors?.ip_public}
            />
          </Box>
          <Box mb={2}>
            <FormControlLabel
                control={<Switch checked={formik.values?.use_relay} onChange={formik.handleChange} name="use_relay"/>}
                label="Use Relay?"/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={formik.handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
  );
};

export default VPNHostDialog;
