import React, {useEffect, useState} from "react";
import {Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch, TextField, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import * as yup from "yup";
import {useFormik} from "formik";
import {AlertTitle} from "@mui/lab";
import {getRsaKeys} from "../../services/rsakey.service";

const SystemUserListDialog = ({onClose, onSave, user}) => {
    const [error, setError] = useState("")
    const [rsaKeys, setRsaKeys] = useState([])
    const [loadingRsa, setLoadingRsa] = useState(false)

    const {enqueueSnackbar} = useSnackbar();

    const validationSchema = yup.object({
        username: yup
            .string()
            .required('Username is required')
            .min(3, 'The username must be between 3 and 20 characters.')
            .max(20, 'The username must be between 3 and 20 characters.'),
        email: yup
            .string()
            .required('Email is required')
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 'Invalid email'),
        rsa_key_id: yup
            .string()
            .required('RSA Key is required'),
        password: yup
            .string()
            .when('id', {
                is: id => id,
                then: schema => schema
                    .when('password', {
                        is: password => password && password.length > 0,
                        then: schema => schema
                            .required('Password is required')
                            .min(6, 'The password must be between 6 and 40 characters.')
                            .max(40, 'The password must be between 6 and 40 characters.'),
                        otherwise: schema => schema.notRequired()
                    }),
                otherwise: schema => schema
                    .required('Password is required')
                    .min(6, 'The password must be between 6 and 40 characters.')
                    .max(40, 'The password must be between 6 and 40 characters.'),
            }),
        confirmPassword: yup
            .string()
            .when('password', {
                is: password => password && password.length > 0,
                then: schema => schema.required('Confirm Password is required').oneOf([yup.ref('password'), null], 'Password must match!'),
                otherwise: schema => schema
            })

    });

    const formik = useFormik({
        initialValues: {
            id: null,
            username: "",
            email: "",
            rsa_key_id: "",
            sudo: false,
            groups: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSave(enqueueSnackbar, values, () => {
                onClose()
            }, (res) => setError('Data save failed: ' + (res?.response?.data?.error || res?.message)))
        },
        validateOnChange: !!user?.id,
        validateOnMount: false
    });

    useEffect(() => {
        if (user) {
            formik.setValues({
                id: user.id,
                username: user.username,
                email: user.email,
                rsa_key_id: user.rsa_key_id,
                sudo: user.sudo,
                groups: user.groups
            })
        }

        setLoadingRsa(true)
        getRsaKeys(enqueueSnackbar, res => {
            setRsaKeys(res)
            setLoadingRsa(false)
        }, () => setLoadingRsa(false))
    }, []);

    return <Dialog
        open={true}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
    >
        <DialogTitle>{formik.values?.id ? 'Edit' : 'Add'} System User</DialogTitle>
        {error &&
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
            </Alert>
        }
        <DialogContent>
            <Box mb={2}>
                <TextField
                    autoFocus
                    variant="standard"
                    name="username"
                    label="Username"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values?.username}
                    helperText={formik.errors?.username}
                />
            </Box>
            <Box mb={2}>
                <TextField
                    inputProps={{
                        autoComplete: 'new-email',
                        form: {
                            autoComplete: 'off',
                        },}}
                    autoComplete='off'
                    variant="standard"
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values?.email}
                    helperText={formik.errors?.email}
                />
            </Box>
            <Box mb={2}>
                <TextField
                    inputProps={{
                        autoComplete: 'new-password',
                        form: {
                            autoComplete: 'off',
                        },}}
                    autoComplete='off'
                    variant="standard"
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values?.password}
                    helperText={formik.errors?.password}
                />
            </Box>
            <Box mb={2}>
                <TextField
                    variant="standard"
                    name="confirmPassword"
                    label="Password"
                    type="password"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values?.confirmPassword}
                    helperText={formik.errors?.confirmPassword}
                />
            </Box>
            <Box mb={2}>
                <FormControl fullWidth variant="standard">
                    <InputLabel>RSA Key</InputLabel>
                    <Select
                        name="rsa_key_id"
                        disabled={loadingRsa}
                        value={loadingRsa ? 1 : formik.values?.rsa_key_id}
                        onChange={formik.handleChange}
                        MenuProps={{PaperProps: {sx: {maxHeight: 300}}}}
                        fullWidth
                    >
                        {loadingRsa ?
                            <MenuItem key={1} value={1}><CircularProgress size={15}/><Typography variant='span' sx={{pl: 1}}>Loading...</Typography></MenuItem>
                            :
                            rsaKeys.map(key => (
                                <MenuItem key={key.id} value={key.id}>{key.key_public}</MenuItem>
                            ))
                        }
                    </Select>
                    <FormHelperText>{formik.errors?.rsa_key_id}</FormHelperText>
                </FormControl>
            </Box>
            <Box mb={2}>
                <TextField
                    variant="standard"
                    name="groups"
                    label="Groups"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values?.groups}
                    helperText={formik.errors?.groups}
                />
            </Box>
            <Box mb={2}>
                <FormControlLabel
                    control={<Switch checked={formik.values?.sudo} onChange={formik.handleChange} name="sudo"/>}
                    label="Sudo"/>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cancel
            </Button>
            <Button onClick={formik.handleSubmit} color="primary">
                Save
            </Button>
        </DialogActions>
    </Dialog>
}

export default SystemUserListDialog;