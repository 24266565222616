import React from "react";
import darkTheme from "../../themes/defaulTheme";
import {Box, Button, CircularProgress, Grid, IconButton, Paper, ThemeProvider, Typography} from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import {Link} from "react-router-dom";
import ForwardIcon from '@mui/icons-material/Forward';

const LastModifiedPanel = ({title, array, listElement, link, loading}) => {
    return <Box mt={5} mr={2} ml={2}>
        <ThemeProvider theme={darkTheme}>
            <Paper className="paper-last-modified-wrapper">
                <Typography variant='h5'>{title}</Typography>
                <Grid container className="list-container">
                    {!loading ? array && array.length > 0 ? array.map(el => {
                        return <Grid>
                            {listElement(el)}
                        </Grid>
                    }) : <Grid container direction="column" alignItems="center" className="no-rows">
                        <IconButton>
                            <DangerousIcon fontSize="large"/>
                        </IconButton>
                        <Typography variant="h6" color="textSecondary" align="center">
                            You don't have any {title}
                        </Typography>
                    </Grid> : <Box container className='loading-screen'>
                        <CircularProgress/>
                    </Box>}
                </Grid>
                <Button color="primary" fullWidth className='list-button-link'>
                    <Link to={link}>
                        Go to {title}
                        <ForwardIcon/>
                    </Link>
                </Button>
            </Paper>
        </ThemeProvider>
    </Box>
}

export default LastModifiedPanel;