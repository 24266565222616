import React, { useEffect, useRef, useState } from 'react';
import Sidebar from './Sidebar';
import { Box } from '@mui/material';
import './Layout.scss'

const Layout = ({ children }) => {
    const [showSidebar, setShowSidebar] = useState(false)
    const sidebarRef = useRef()

    useEffect(() => {
        function handleClickOutside(event) {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setShowSidebar(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sidebarRef]);

    return (
        <Box id='layout'>
            <Box className={`layout-sidebar-wrapper ${showSidebar ? 'show-sidebar' : 'hide-sidebar'}`}
                ref={sidebarRef}
                onClick={() => setShowSidebar(!showSidebar)}
            >
                <Sidebar/>
            </Box>
            <Box id="page-content-wrapper">
                {children}
            </Box>
        </Box>
    )
};

export default Layout;
