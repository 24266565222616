import {Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Alert} from '@mui/material';
import React, { useState, useEffect } from 'react';
import {saveVpnInstance} from "../../services/vpnService";
import {AlertTitle} from "@mui/lab";
import {useSnackbar} from "notistack";
import * as yup from "yup";
import {useFormik} from "formik";

const VPNInstanceDialog = ({ onClose, currentInstance}) => {
  const [error, setError] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object({
    name: yup
        .string()
        .required('Instance name is required'),
    network: yup
        .string()
        .required('Instance network is required')
        .matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i, 'Invalid network'),
    port: yup
        .number()
        .required('Instance port is required'),
    mask: yup
        .number()
        .required('Instance mask is required')
  });

  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      network: "",
      port: "",
      mask: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveVpnInstance(enqueueSnackbar, values?.id, values, () => {
        onClose()
      }, (response) => setError('Data save failed: ' + (response?.response?.data?.error || response?.message)))
    },
    validateOnChange: !!currentInstance?.id,
    validateOnMount: false
  });

  useEffect(() => {
    if (currentInstance) {
      formik.setValues({
        id: currentInstance?.id,
        name: currentInstance?.name,
        network: currentInstance?.network,
        port: currentInstance?.port,
        mask: currentInstance?.mask
      })
    }
  }, []);

  return (
    <Dialog
        open={true}
        onClose={onClose}
        maxWidth='sm'
        fullWidth={true}
    >
      <DialogTitle>{formik.values?.id ? 'Edit' : 'New'} VPN Instance</DialogTitle>
      {error &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
      }
      <DialogContent>
        <Box mb={2} mt={1}>
          <TextField
              variant="standard"
              name="name"
              label="Instance Name"
              value={formik.values?.name}
              onChange={formik.handleChange}
              helperText={formik.errors?.name}
              fullWidth
          />
        </Box>
        <Box mb={2} mt={1}>
          <TextField
              variant="standard"
              name="network"
              label="Instance Network"
              value={formik.values?.network}
              onChange={formik.handleChange}
              helperText={formik.errors?.network}
              fullWidth
          />
        </Box>
        <Box mb={2} mt={1}>
          <TextField
              variant="standard"
              name="mask"
              label="Instance Mask"
              value={formik.values?.mask}
              onChange={formik.handleChange}
              helperText={formik.errors?.mask}
              fullWidth
          />
        </Box>
        <Box mb={2} mt={1}>
          <TextField
              variant="standard"
              name="port"
              label="Instance Port"
              value={formik.values?.port}
              onChange={formik.handleChange}
              helperText={formik.errors?.port}
              fullWidth
          />
        </Box>
      </DialogContent >
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={formik.handleSubmit} color="primary">Save</Button>
      </DialogActions>
    </Dialog >
  );
};

export default VPNInstanceDialog;
