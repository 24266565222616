// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: localStorage.getItem('user') != null,
    userEmail: null,
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.userEmail = action.payload;
    },
    logout: state => {
      state.isAuthenticated = false;
      state.userEmail = null;
    }
  }
});

export const { login, logout } = authSlice.actions;

export const selectIsAuthenticated = state => state.auth.isAuthenticated;
export const selectUserEmail = state => state.auth.userEmail;

export default authSlice.reducer;
