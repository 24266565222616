import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const DeleteDialog = ({ onClose, message, onDelete }) => {
  const handleDelete = async () => {
    await onDelete();
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} color="secondary">Delete</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
