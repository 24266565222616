import React from "react";

const apiHandler = (commonMessage, config, fn, enqueueSnackbar, showSuccessMessage = true, showErrorMessage = true, errorCallback) => {
    const user = JSON.parse(localStorage.getItem('user'));
    config = {
        ...config,
        headers:{
            Authorization: `Bearer ${user.token}`,
        }
    }

    return fn(config).then(() => {
        if (showSuccessMessage) {
            enqueueSnackbar(commonMessage, {variant: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 1500})
        }
    }).catch(exception => {
        if (exception?.response?.status === 401) {
            enqueueSnackbar(`${exception?.message}`, {variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }})
            localStorage.removeItem('user');
            window.location.href = '/signin';
        } else {
            if(showErrorMessage){
                enqueueSnackbar(`${commonMessage} - ${exception?.response?.data?.msg}`, {variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 2500})
            }
            errorCallback && errorCallback(exception)
        }
        throw exception
    })
};

export default apiHandler;