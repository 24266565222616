import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {TextField, Typography, Grid, Box, Alert, Button, ThemeProvider, Paper} from '@mui/material';
import {register} from "../../actions/auth";
import './RegisterPage.scss'
import {LoadingButton} from "@mui/lab";
import * as yup from 'yup';
import {useFormik} from "formik";
import {Link} from "react-router-dom";
import darkTheme from "../../themes/defaulTheme";

const RegisterPage = () => {
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [emptyFields, setEmptyFields] = useState({
        username: false,
        email: false,
        password: false,
        confirmPassword: false,
    })
    const dispatch = useDispatch();

    const validationSchema = yup.object({
        username: yup
            .string()
            .required('The username is required')
            .min(3, 'The username must be between 3 and 20 characters.')
            .max(20, 'The username must be between 3 and 20 characters.'),
        email: yup
            .string()
            .required('Email is required')
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 'Invalid email'),
        password: yup
            .string()
            .required('The password is required')
            .min(5, 'The password must be between 6 and 40 characters.')
            .max(40, 'The password must be between 6 and 40 characters.'),
        confirmPassword: yup
            .string()
            .required('The password confirmation is required')
            .oneOf([yup.ref('password'), null], 'Password must match!')
    });

    const formik = useFormik({
        initialValues: {
            username: "",
            email: "",
            password: "",
            confirmPassword: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            setSuccessful(false);

            dispatch(register(values.username, values.email, values.password))
                .then(() => {
                    setLoading(false)
                    setSuccessful(true);
                })
                .catch(() => {
                    setLoading(false)
                    setSuccessful(false);
                });
        },
        validateOnChange: false,
        validateOnMount: false
    });

    const onFocusError = (key) => {
        setEmptyFields({
            ...emptyFields,
            [key]: false
        })
    }

    const backButton = (
        <Button color="primary" size="small">
            <Link to="/signip" style={{textDecoration: 'none', color: 'inherit'}}>
                Back to Login
            </Link>
        </Button>
    );

    useEffect(() => {
        if (!formik.isSubmitting) {
            setEmptyFields({
                username: !!formik.errors.username,
                email: !!formik.errors.email,
                password: !!formik.errors.password,
                confirmPassword: !!formik.errors.confirmPassword,
            })
        }
    }, [formik.isSubmitting]);

    return (
        <ThemeProvider theme={darkTheme}>
            <Paper elevation={10} className="paper-wrapper">
                <Box id="register-page">
                    <Typography variant="h4" component="h1" gutterBottom>
                        Register
                    </Typography>
                    {successful ?
                        <Alert className="success-message" severity="success">Registration successful! {backButton}</Alert>
                        :
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        inputProps={{ autoComplete: 'off'}}
                                        fullWidth
                                        label="Username"
                                        name="username"
                                        value={formik.values?.username}
                                        onChange={formik.handleChange}
                                        error={emptyFields.username}
                                        helperText={formik.errors.username}
                                        onFocus={() => onFocusError('username')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        inputProps={{
                                            autoComplete: 'new-email',
                                            form: {
                                                autoComplete: 'off',
                                            },}}
                                        autoComplete='off'
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        type="email"
                                        value={formik.values?.email}
                                        onChange={formik.handleChange}
                                        error={emptyFields.email}
                                        helperText={formik.errors.email}
                                        onFocus={() => onFocusError('email')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        inputProps={{
                                            autoComplete: 'new-password',
                                            form: {
                                                autoComplete: 'off',
                                            },}}
                                        autoComplete='off'
                                        fullWidth
                                        label="Password"
                                        name="password"
                                        type="password"
                                        value={formik.values?.password}
                                        onChange={formik.handleChange}
                                        error={emptyFields.password}
                                        helperText={formik.errors.password}
                                        onFocus={() => onFocusError('password')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        inputProps={{ form: { autoComplete: 'off'}}}
                                        fullWidth
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        type="password"
                                        value={formik.values?.confirmPassword}
                                        onChange={formik.handleChange}
                                        error={emptyFields.confirmPassword}
                                        helperText={formik.errors.confirmPassword}
                                        onFocus={() => onFocusError('confirmPassword')}
                                    />
                                </Grid>
                                <Grid item xs={12} className="submit-buttons">
                                    <Link to="/signip" style={{textDecoration: 'none', color: 'inherit'}}>
                                        <LoadingButton variant="contained">
                                            Back to Login
                                        </LoadingButton>
                                    </Link>
                                    <LoadingButton variant="contained" type="submit" loading={loading}>
                                        Register
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    }
                </Box>
            </Paper>
        </ThemeProvider>
    );
};

export default RegisterPage;
