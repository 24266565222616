import React, { useState, useEffect } from 'react';
import { deleteVpnInstance, getVpnInstances } from '../../services/vpnService';
import {Box, Container, Button, Typography, IconButton} from '@mui/material';
import {DataGrid, GridOverlay, GridToolbar} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from "../common/DeleteDialog";
import VPNInstanceDialog from "./VPNInstanceDialog";
import { useSnackbar } from "notistack";
import CustomNoRowsOverlay from "../common/CustomNoRowsOverlay";

const VPNInstancePage = () => {
    const [vpnInstances, setVpnInstances] = useState([]);
    const [instanceDialogOpen, setInstanceDialogOpen] = useState({ show: false, data: null });
    const [deleteDialogOpen, setDeleteDialogOpen] = useState({ show: false, data: null });
    const [loadingInstances, setLoadingInstances] = useState(false)


    const columns = [
        { field: 'id', headerName: 'ID', minWidth: 50, flex: 1 },
        { field: 'name', headerName: 'Name', minWidth: 200, flex: 2 },
        { field: 'network', headerName: 'Network', minWidth: 200, flex: 1 },
        { field: 'mask', headerName: 'Mask', minWidth: 50, flex: 1 },
        { field: 'port', headerName: 'Port', minWidth: 100, flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Button onClick={() => handleEdit(params.row)}><EditIcon /></Button>
                        <Button onClick={() => handleDelete(params.row)}><DeleteIcon /></Button>
                    </>
                )
            }
        },
    ];

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        reload()
    }, []);

    const reload = () => {
        setLoadingInstances(true)
        getVpnInstances(enqueueSnackbar, instances => {
            setLoadingInstances(false)
            setVpnInstances(instances.sort((a, b) => a.id - b.id))
        }, () => setLoadingInstances(false))
    }

    const handleDeleteInstance = (id) => {
        deleteVpnInstance(enqueueSnackbar, id, () => {
            setVpnInstances([])
            setDeleteDialogOpen({ show: false, data: null });
            reload()
        })
    }

    const handleDelete = (rowData) => {
        setDeleteDialogOpen({ show: true, data: rowData });
    };

    const handleDeleteClose = () => {
        setDeleteDialogOpen({ show: false, data: null });
    };

    const handleEdit = (rowData) => {
        setInstanceDialogOpen({ show: true, data: rowData });
    };

    const handleEditClose = (rowData) => {
        setInstanceDialogOpen({ show: false, data: null });
        reload();
    };

    return (
        <Container id="instances-page" maxWidth="xl">
            <Box className="box-main">
                <Typography variant="h5">VPN Instances</Typography>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setInstanceDialogOpen({ show: true, data: null })}>
                    Add New VPN Instance
                </Button>
            </Box>
            <Box className={`box-list ${loadingInstances && vpnInstances?.length === 0 ? 'loading-screen' : ''} ${vpnInstances?.length > 0 ? '' : 'empty-table'}`}>
                <DataGrid
                    rows={vpnInstances}
                    loading={loadingInstances}
                    columns={columns}
                    slots={{toolbar: GridToolbar, noRowsOverlay: () => <CustomNoRowsOverlay message={'No instances available'}/>}}
                    className="box-list-table"
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 25 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 25, 100]}
                />
            </Box>
            {deleteDialogOpen?.show && <DeleteDialog
                onClose={() => handleDeleteClose()}
                message={`Are you sure you want to delete the VPN instance ${deleteDialogOpen?.data?.name}?`}
                onDelete={() => handleDeleteInstance(deleteDialogOpen?.data?.id)}
            />}
            {instanceDialogOpen?.show && <VPNInstanceDialog
                onClose={() => handleEditClose()}
                currentInstance={instanceDialogOpen?.data}
            />}
        </Container>
    );
}

export default VPNInstancePage;
