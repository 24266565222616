import axios from 'axios';
import apiHandler from "./ApiHandler";

export const getVpnInstances = (enqueueSnackbar, callback, errorCallback) => {
  const config = {
    url: `/api/vpn/instance`,
  }
  apiHandler("Getting Instances", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, false, true, errorCallback)
}

export const getHosts = (enqueueSnackbar,  instanceId, callback, errorCallback) => {
  const config = {
    url: `/api/vpn/instance/${instanceId}/host`,
  }
  apiHandler("Getting Hosts", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, false, true, errorCallback)
}

export const removeHost = (enqueueSnackbar, instanceId, hostId, callback) => {
  const config = {
    url: `/api/vpn/instance/${instanceId}/host/${hostId}`,
    method: 'DELETE'
  }
  apiHandler("Remove Host", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar)
}

export const saveHost = (enqueueSnackbar, instanceId, hostId, hostData, callback, errorCallback) => {
  const config = {
    url: hostId ? `/api/vpn/instance/${instanceId}/host/${hostId}` : `/api/vpn/instance/${instanceId}/host`,
    method: hostId ? 'POST' : 'PUT',
    data: hostData
  }
  apiHandler("Save Host", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, true, false, errorCallback)
}

export const getPermanentInstallLink = (enqueueSnackbar, tokenData, callback) => {
  const config = {
    url: `/api/vpn/token/install`,
    method: 'POST',
    data: tokenData
  }
  apiHandler("Getting Permanent Download Link", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar)
}

export const getPermanentUninstallLink = (enqueueSnackbar, tokenData, callback) => {
  const config = {
    url: `/api/vpn/token/uninstall`,
    method: 'POST',
    data: tokenData
  }
  apiHandler("Getting Permanent Download Link", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar)
}

export const saveVpnInstance = (enqueueSnackbar, instanceId, instanceData, callback, errorCallback) => {
  const config = {
    url: instanceId ? `/api/vpn/instance/${instanceId}` : '/api/vpn/instance',
    method: instanceId ? 'POST' : 'PUT',
    data: instanceData
  }
  apiHandler("Save Vpn Instance", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, true, false, errorCallback)
}

export const deleteVpnInstance = (enqueueSnackbar, instanceId, callback) => {
  const config = {
    url: `/api/vpn/instance/${instanceId}`,
    method: 'DELETE',
  }
  apiHandler("Deleting Vpn Instance", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar)
}

export const downloadScript = (enqueueSnackbar, hostId, callback) => {
  const config = {
    url: `/api/vpn/host/${hostId}/install`,
    data: { responseType: 'blob' }
  }
  apiHandler("Download Script", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar)
}

export const getFreeIPs = (enqueueSnackbar, instanceId, callback, errorCallback) => {
  const config = {
    url: `/api/vpn/instances/${instanceId}/addresses`
  }
  apiHandler("Getting Free IPs", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, true, true, errorCallback)
}