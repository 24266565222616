import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const darkTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
        '&$warning': {
          color: '#db3131'
        },
      }
    },
  },
  colorMenu:'#fff',
  palette: {
    mode: 'dark',  // Adding dark mode property
    primary: {
      main: '#009788',
      contrastText: '#fff', // Adding contrast text for readability
    },
    gray: {
      main: '#B1B6C0',
      contrastText: '#fff', // Adding contrast text for readability
    },
    action: {
      main: '#daa081',
      contrastText: '#000', // Adding contrast text for readability
    },
    secondary: {
      main: '#db3131',
      contrastText: '#fff', // Adding contrast text for readability
    },
    error: {
      main: red.A400,
      contrastText: '#fff', // Adding contrast text for readability
    },
    background: {
      default: "#181818", // Darker background for dark mode
      // paper: '#181818', // Darker paper background
    },
    text: {
      primary: '#fff', // Light text for dark mode
      secondary: '#bdbdbd', // Light secondary text for dark mode
    },
  },
});

export default darkTheme;
