import axios from 'axios';
import apiHandler from "./ApiHandler";

export const getRsaKeys = (enqueueSnackbar, callback, errorCallback) => {
  const config = {
    url: '/api/rsa-key',
  }
  apiHandler("Getting Rsa Keys", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, false, true, errorCallback)
}

export const getRsaKey = (enqueueSnackbar, id, callback, errorCallback) => {
  const config = {
    url: `/api/rsa-key/${id}`,
  }
  apiHandler("Getting Rsa Key", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, true, false, errorCallback)
}

export const saveRsaKey = (enqueueSnackbar, keyData, callback, errorCallback) => {
  const config = {
    url: keyData?.id ? `/api/rsa-key/${keyData.id}` : '/api/rsa-key',
    method: keyData?.id ? 'POST' : 'PUT',
    data: keyData
  }
  apiHandler("Save Rsa Key", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar, true, false, errorCallback)
}

export const deleteRsaKey = (enqueueSnackbar, id, callback) => {
  const config = {
    url: `/api/rsa-key/${id}`,
    method: 'DELETE'
  }
  apiHandler("Deleting Rsa Key", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar)
}

export const generateRsaKey = (enqueueSnackbar, callback) => {
  const config = {
    url: '/api/rsa-key/generate',
  }
  apiHandler("Generate Rsa Key", config, async config => {
    const response = await axios.request(config);
    callback && callback(response.data)
  }, enqueueSnackbar)
}
