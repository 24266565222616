import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, InputLabel, FormControl, Alert, Box, CircularProgress, Typography, FormHelperText } from '@mui/material';
import {useSnackbar} from "notistack";
import { getHosts, getVpnInstances } from '../../services/vpnService';
import { getProxyDomains } from '../../services/proxyDomain.service';
import {AlertTitle} from "@mui/lab";
import * as yup from "yup";
import {useFormik} from "formik";

const ProxyHostDialog = ({ onClose, onSave, host }) => {
  const [error, setError] = useState("");
  const [loadingInstances, setLoadingInstances] = useState(false)
  const [loadingDomains, setLoadingDomains] = useState(false)
  const [loadingHosts, setLoadingHosts] = useState(false)
  const [vpnHosts, setVpnHosts] = useState([]);
  const [vpnInstances, setVpnInstances] = useState([]);
  const [domains, setDomains] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object({
    host: yup
        .string()
        .required('Host name is required'),
    domain_id: yup
        .string()
        .required('Domain is required'),
    vpn_id: yup
        .string()
        .required('VPN is required'),
    host_id: yup
        .string()
        .required('Host is required'),
    port: yup
        .string()
        .required('Port is required')
  });

  const formik = useFormik({
    initialValues: {
      id: null,
      host: "",
      domain_id: "",
      vpn_id: "",
      host_id: "",
      port: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSave(enqueueSnackbar, values, () => {
        onClose();
      }, (res) => setError('Data save failed: ' + (res?.response?.data?.error || res?.message)))
    },
    validateOnChange: !!host?.id,
    validateOnMount: false
  });

  useEffect(() => {
    if (formik.values?.vpn_id) {
      setLoadingHosts(true)
        getHosts(enqueueSnackbar, formik.values?.vpn_id, res => {
          setVpnHosts(res);
          setLoadingHosts(false)
        }, () => setLoadingHosts(false))
    }
  }, [formik.values?.vpn_id])

  useEffect(() => {
    if (host) {
      formik.setValues({
        id: host.id,
        host: host.host,
        domain_id: host.domain_id,
        vpn_id: host.vpn_host?.vpn_instance_id,
        host_id: host.host_id,
        port: host.port
      })
    }

    setLoadingInstances(true)
    getVpnInstances(enqueueSnackbar, res => {
      setVpnInstances(res.sort((a, b) => a.id - b.id))
      setLoadingInstances(false)
    }, () => setLoadingInstances(false))

    setLoadingDomains(true)
    getProxyDomains(enqueueSnackbar, res => {
      setDomains(res.sort((a, b) => a.id - b.id))
      setLoadingDomains(false)
    }, () => setLoadingDomains(false))
  }, []);

  return (
    <Dialog
        open={true}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
    >
      <DialogTitle>{formik.values?.id ? 'Edit' : 'Add'} Domain</DialogTitle>
      {error &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
      }
      <DialogContent>
        <Box mb={2} mt={1}>
          <TextField
              variant="standard"
              name="host"
              label="Host"
              value={formik.values?.host}
              onChange={formik.handleChange}
              fullWidth
              helperText={formik.errors?.host}
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Domain</InputLabel>
            <Select
                name="domain_id"
                value={loadingDomains ? 1 : formik.values?.domain_id}
                onChange={formik.handleChange}
                MenuProps={{PaperProps: {sx: {maxHeight: 300}}}}
                fullWidth
            >
              {loadingDomains ?
                  <MenuItem key={1} value={1}><CircularProgress size={15}/><Typography variant='span' sx={{pl: 1}}>Loading...</Typography></MenuItem>
                  :
                  domains.map(i => (
                      <MenuItem key={i.id} value={i.id}>{i.domain}</MenuItem>
                  ))
              }
            </Select>
            <FormHelperText>{formik.errors?.domain_id}</FormHelperText>
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="standard">
            <InputLabel>VPN</InputLabel>
            <Select
                name="vpn_id"
                value={loadingInstances ? 1 : formik.values?.vpn_id}
                onChange={formik.handleChange}
                MenuProps={{PaperProps: {sx: {maxHeight: 300}}}}
                fullWidth
            >
              {loadingInstances ?
                  <MenuItem key={1} value={1}><CircularProgress size={15}/><Typography variant='span' sx={{pl: 1}}>Loading...</Typography></MenuItem>
                  :
                  vpnInstances.map(i => (
                      <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                  ))
              }
            </Select>
            <FormHelperText>{formik.errors?.vpn_id}</FormHelperText>
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Destination</InputLabel>
            <Select
                name="host_id"
                value={loadingHosts ? 1 : formik.values?.host_id}
                onChange={formik.handleChange}
                MenuProps={{PaperProps: {sx: {maxHeight: 300}}}}
                fullWidth
            >
              {loadingHosts ?
                  <MenuItem key={1} value={1}><CircularProgress size={15}/><Typography variant='span' sx={{pl: 1}}>Loading...</Typography></MenuItem>
                  :
                  vpnHosts.map(i => (
                      <MenuItem key={i.id} value={i.id}>{i.host}</MenuItem>
                  ))
              }
            </Select>
            <FormHelperText>{formik.errors?.host_id}</FormHelperText>
          </FormControl>
        </Box>
        <Box mb={2}>
          <TextField
              variant="standard"
              name="port"
              label="Port"
              value={formik.values?.port}
              onChange={formik.handleChange}
              fullWidth
              helperText={formik.errors?.port}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={formik.handleSubmit} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProxyHostDialog;