import React, { useState, useEffect } from 'react';
import {deleteSystemUser, getSystemUsers, saveSystemUser} from '../../services/system-user.service';
import {Button, Container, Typography, Box} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {useSnackbar} from "notistack";
import CustomNoRowsOverlay from "../common/CustomNoRowsOverlay";
import SystemUserListDialog from "./SystemUserListDialog";
import DeleteDialog from "../common/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const SystemUserList = () => {
  const [systemUsers, setSystemUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [openSystemUserDialog, setOpenSystemUserDialog] = useState({show: false, user: null});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState({show: false, user: null});

  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    { field: 'id', headerName: 'ID', minWidth: 50 },
    { field: 'username', headerName: 'Username', minWidth: 200, flex: 1 },
    { field: 'email', headerName: 'Email', minWidth: 200, flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        return (
            <>
              <Button onClick={() => handleOpenEditUserDialog(params.row)}><EditIcon/></Button>
              <Button onClick={() => handleDeleteDialogOpen(params.row)}><DeleteIcon/></Button>
            </>
        )
      }
    },
  ];

  const reload = () => {
    setLoadingUsers(true)
    getSystemUsers(enqueueSnackbar, users => {
      setSystemUsers(users.sort((a, b) => a.id - b.id))
      setLoadingUsers(false)
    }, () => setLoadingUsers(false))
  };

  const handleOpenAddUserDialog = () => {
    setOpenSystemUserDialog({show: true, user: null})
  }

  const handleOpenEditUserDialog = (user) => {
    setOpenSystemUserDialog({show: true, user: user})
  }

  const handleCloseUserDialog = () => {
    setOpenSystemUserDialog({show: false, user: null})
    reload()
  }

  const handleDeleteDialogOpen = (user) => {
    setDeleteDialogOpen({show: true, user: user})
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen({show: false, user: null})
  }

  const handleDeleteUser = (user) => {
    deleteSystemUser(enqueueSnackbar, user.id, () => reload())
  };

  useEffect(() => {
    reload();
  }, []);

  return (
    <Container id="system-users-page" maxWidth="xl">
      <Box className="box-main">
        <Typography variant="h5">System Users</Typography>
        <Button variant="contained" color="primary" onClick={handleOpenAddUserDialog}>Add System User</Button>
      </Box>
      <Box className={`box-list ${loadingUsers && systemUsers?.length === 0 ? 'loading-screen' : ''} ${systemUsers?.length > 0 ? '' : 'empty-table'}`}>
        <DataGrid
            rows={systemUsers}
            loading={loadingUsers}
            slots={{
              toolbar: GridToolbar,
              noRowsOverlay: () => <CustomNoRowsOverlay message={'No System Users available'}/>
            }}
            className="box-list-table"
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {page: 0, pageSize: 5},
              },
            }}
            pageSizeOptions={[5, 10]}
        />
        {openSystemUserDialog?.show &&
            <SystemUserListDialog
                onClose={handleCloseUserDialog}
                onSave={saveSystemUser}
                user={openSystemUserDialog?.user}
            />
        }
        {deleteDialogOpen?.show &&
            <DeleteDialog
                onClose={handleDeleteDialogClose}
                message={`Are you sure you want to delete the User: ${deleteDialogOpen?.user?.username}?`}
                onDelete={() => handleDeleteUser(deleteDialogOpen?.user)}
            />
        }
      </Box>
    </Container>
  );
};

export default SystemUserList;
