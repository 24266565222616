import React, {useEffect, useState} from 'react';
import {Button, Container, Typography, Box} from '@mui/material';
import RsaKeyDialog from './RsaKeyDialog';
import {deleteRsaKey, getRsaKeys} from '../../services/rsakey.service';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {useSnackbar} from "notistack";
import RenderCellTooltip from "../common/RenderCellTooltip";
import CustomNoRowsOverlay from "../common/CustomNoRowsOverlay";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../common/DeleteDialog";
import AddIcon from "@mui/icons-material/Add";

const RsaKeyList = () => {
    const [rsaKeys, setRsaKeys] = useState([]);
    const [dialogOpen, setDialogOpen] = useState({show: false, rsaKey: null});
    const [deleteDialog, setDeleteDialog] = useState({show: false, rsaKey: null});
    const [loadingKeys, setLoadingKeys] = useState(false);

    const {enqueueSnackbar} = useSnackbar();


    const columns = [
        {field: 'id', headerName: 'ID', minWidth: 50, flex: 1},
        {field: 'key_private', headerName: 'Private Key', minWidth: 200, flex: 3, renderCell: RenderCellTooltip},
        {field: 'key_public', headerName: 'Public Key', minWidth: 200, flex: 3, renderCell: RenderCellTooltip},
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Button onClick={() => handleEdit(params.row)}><EditIcon/></Button>
                        <Button onClick={() => handleDelete(params.row)}><DeleteIcon/></Button>
                    </>
                )
            }
        },
    ];

    const handleDialogClose = () => {
        setDialogOpen({show: false, rsaKey: null});
        reload();
    };

    const handleAddRsaKey = () => {
        setDialogOpen({show: true, rsaKey: null});
    };

    const handleEdit = (rsaKey) => {
        setDialogOpen({show: true, rsaKey: rsaKey});
    }

    const handleDelete = (rsaKeys) => {
        setDeleteDialog({show: true, rsaKey: rsaKeys})
    }

    const handleDeleteClose = () => {
        setDeleteDialog({show: false, rsaKey: null})
    }

    const handleDeleteRsaKey = (rsaKeys) => {
        deleteRsaKey(enqueueSnackbar, rsaKeys?.id, () => reload())
    }

    const reload = () => {
        setLoadingKeys(true)
        getRsaKeys(enqueueSnackbar, res => {
            setRsaKeys(res.sort((a, b) => a.id - b.id))
            setLoadingKeys(false)
        }, () => setLoadingKeys(false))
    }

    useEffect(() => {
        reload();
    }, []);

    return (
        <Container id="rsa-key-page" maxWidth="xl">
            <Box className="box-main">
                <Typography variant="h5">RSA Keys</Typography>
                <Button variant="contained" color="primary" onClick={handleAddRsaKey} startIcon={<AddIcon/>}>Add new RSA Key</Button>
            </Box>
            <Box className={`box-list ${loadingKeys && rsaKeys?.length === 0 ? 'loading-screen' : ''} ${rsaKeys?.length > 0 ? '' : 'empty-table'}`}>
                <DataGrid
                    rows={rsaKeys}
                    loading={loadingKeys}
                    columns={columns}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: () => <CustomNoRowsOverlay message={'No RSA keys available'}/>
                    }}
                    className="box-list-table"
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 5},
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </Box>
            {dialogOpen?.show &&
                <RsaKeyDialog onClose={handleDialogClose} rsaKey={dialogOpen?.rsaKey}/>
            }
            {deleteDialog.show &&
                <DeleteDialog
                    onClose={() => handleDeleteClose()}
                    message={`Are you sure you want to delete the RSA Key with ID: ${deleteDialog?.rsaKey?.id}?`}
                    onDelete={() => handleDeleteRsaKey(deleteDialog?.rsaKey)}
                />
            }
        </Container>
    );
};

export default RsaKeyList;