import {Container, Typography, ListItemButton, Grid} from '@mui/material';
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {getHosts, getVpnInstances} from "../../services/vpnService";
import {getRsaKeys} from "../../services/rsakey.service";
import {getSystemUsers} from "../../services/system-user.service";
import {getProxyDomains} from "../../services/proxyDomain.service";
import {getProxyHosts} from "../../services/proxyHost.service";
import LastModifiedPanel from "./LastModifiedPanel";
import './Home.scss'
import {NavLink} from "react-router-dom";

const Home = () => {
    const [instances, setInstances] = useState([])
    const [hosts, setHosts] = useState([])
    const [RSAKeys, setRSAKeys] = useState([])
    const [systemUsers, setSystemUsers] = useState([])
    const [domains, setDomains] = useState([])
    const [proxyHosts, setProxyHosts] = useState([])

    const [loading, setLoading] = useState([false,false,false,false,false,false])

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {


        // This should be rebuilt for one request and add a modified_at column to the db




        setLoading([true,true,true,true,true,true])
        getVpnInstances(enqueueSnackbar, instances => {
            setInstances(instances.sort((a, b) => b.modified_at - a.modified_at).slice(0, 10))
            loading[0] = false
            setLoading([...loading])
            let all_hosts = [];
            for (let instance of instances) {
                getHosts(enqueueSnackbar, instance.id, hosts => {
                    all_hosts = all_hosts.concat(hosts.map(host => ({...host, instance })));
                    setHosts(all_hosts.sort((a, b) => b.modified_at - a.modified_at).slice(0, 10));
                });
            }
            loading[1] = false
            setLoading([...loading])
        });

        getRsaKeys(enqueueSnackbar, res => {
            setRSAKeys(res.sort((a, b) => b.modified_at - a.modified_at).slice(0, 10))
            loading[2] = false
            setLoading([...loading])
        }, () => {
            loading[2] = false
            setLoading([...loading])
        })

        getSystemUsers(enqueueSnackbar, res => {
            setSystemUsers(res.sort((a, b) => b.modified_at - a.modified_at).slice(0, 10))
            loading[3] = false
            setLoading([...loading])
        }, () => {
            loading[3] = false
            setLoading([...loading])
        })

        getProxyDomains(enqueueSnackbar, res => {
            setDomains(res.sort((a, b) => b.modified_at - a.modified_at).slice(0, 10))
            loading[4] = false
            setLoading([...loading])
        }, () => {
            loading[4] = false
            setLoading([...loading])
        })

        getProxyHosts(enqueueSnackbar, res => {
            setProxyHosts(res.sort((a, b) => b.modified_at - a.modified_at).slice(0, 10))
            loading[5] = false
            setLoading([...loading])
        }, () => {
            loading[5] = false
            setLoading([...loading])
        })
    }, [])

  return (
    <Container maxWidth="xl" id='home-page'>
        <Grid container>
            <LastModifiedPanel
                title="Instances"
                array={instances}
                link="/vpn"
                loading={loading[0]}
                listElement={el => <ListItemButton disableRipple component={NavLink} className='list-element'>
                    <Typography variant="subtitle1">
                        {el?.name}
                    </Typography>
                    <Typography variant="caption">
                        {el?.network} / {el?.mask} / {el?.port}
                    </Typography>
                </ListItemButton>}
            />
            <LastModifiedPanel
                title="Hosts"
                array={hosts}
                link="/hosts"
                loading={loading[1]}
                listElement={el => <ListItemButton disableRipple component={NavLink} className='list-element'>
                    <Typography variant="subtitle1">
                        {el?.host}
                    </Typography>
                    <Typography variant="caption">
                        {el?.ip_vpn} / {instances.filter(i => i.id === el.vpn_instance_id)[0]?.name}
                    </Typography>
                </ListItemButton>}
            />
            <LastModifiedPanel
                title="RSA Keys"
                array={RSAKeys}
                link="/rsa-keys"
                loading={loading[2]}
                listElement={el => <ListItemButton disableRipple component={NavLink} className='list-element'>
                    <Typography variant="subtitle1">
                        {el?.key_public}
                    </Typography>
                </ListItemButton>}
            />
            <LastModifiedPanel
                title="System Users"
                array={systemUsers}
                link="/system-users"
                loading={loading[3]}
                listElement={el => <ListItemButton disableRipple component={NavLink} className='list-element'>
                    <Typography variant="subtitle1">
                        {el?.username}
                    </Typography>
                    <Typography variant="caption">
                        {el?.email} / {el.groups}
                    </Typography>
                </ListItemButton>}
            />
            <LastModifiedPanel
                title="Proxy Domains"
                array={domains}
                link="/proxy-domains"
                loading={loading[4]}
                listElement={el => <ListItemButton disableRipple component={NavLink} className='list-element'>
                    <Typography variant="subtitle1">
                        {el?.domain}
                    </Typography>
                </ListItemButton>}
            />
            <LastModifiedPanel
                title="Proxy Hosts"
                array={proxyHosts}
                link="/proxy-hosts"
                loading={loading[5]}
                listElement={el => <ListItemButton disableRipple component={NavLink} className='list-element'>
                    <Typography variant="subtitle1">
                        {el?.host}
                    </Typography>
                    <Typography variant="caption">
                        {el?.domain?.domain} / {el?.vpn_host?.host} / {el?.vpn_host?.ip_vpn}
                    </Typography>
                </ListItemButton>}
            />
        </Grid>
    </Container>
  );
};

export default Home;
