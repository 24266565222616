import React, {useEffect, useState} from 'react';
import {Button, Container, Box, Typography} from '@mui/material';
import {useSnackbar} from "notistack";
import {deleteProxyHost, getProxyHosts, saveProxyHost} from '../../services/proxyHost.service';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import ProxyHostDialog from './ProxyHostDialog';
import CustomNoRowsOverlay from "../common/CustomNoRowsOverlay";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../common/DeleteDialog";

const ProxyHosts = () => {
    const [proxyHosts, setProxyHosts] = useState([]);
    const [proxyHostDialogOpen, setProxyHostDialogOpen] = useState({show: false, host: null});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState({show: false, host: null});
    const [loadingHosts, setLoadingHosts] = useState(false);

    const {enqueueSnackbar} = useSnackbar();

    const columns = [
        {field: 'id', headerName: 'ID', minWidth: 50},
        {field: 'host', headerName: 'Host', minWidth: 150, flex: 1},
        {field: 'domain', headerName: 'Domain', minWidth: 150, flex: 1, valueGetter: (params) => params.row.domain.domain},
        {field: 'address', headerName: 'Address', minWidth: 150, flex: 1, valueGetter: (params) => params.row.vpn_host.ip_vpn},
        {field: 'port', headerName: 'Port', minWidth: 150, flex: 1},
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => {
                return (
                    <>
                        <Button onClick={() => handleEdit(params.row)}><EditIcon/></Button>
                        <Button onClick={() => handleDelete(params.row)}><DeleteIcon/></Button>
                    </>
                )
            }
        },
    ];

    const reload = () => {
        setLoadingHosts(true)
        getProxyHosts(enqueueSnackbar, res => {
            setProxyHosts(res.sort((a, b) => a.id - b.id))
            setLoadingHosts(false)
        }, () => setLoadingHosts(false))
    }

    const handleDelete = (host) => {
        setDeleteDialogOpen({show: true, host: host})
    }

    const handleDeleteClose = () => {
        setDeleteDialogOpen({show: false, host: null})
    }

    const handleDeleteHost = (host) => {
        deleteProxyHost(enqueueSnackbar, host.id, () => reload())
    };

    const handleEdit = (host) => {
        setProxyHostDialogOpen({show: true, host: host})
    }

    const handleProxyHostDialogOpen = () => {
        setProxyHostDialogOpen({show: true, host: null});
    };

    const handleProxyHostDialogClose = () => {
        setProxyHostDialogOpen({show: false, host: null});
        reload()
    };

    useEffect(() => {
        reload()
    }, []);

    return (
        <Container id="domains-page" maxWidth="xl">
            <Box className="box-main">
                <Typography variant="h5">Proxy Hosts</Typography>
                <Button variant="contained" color="primary" onClick={handleProxyHostDialogOpen}
                        style={{margin: '10px'}}>Add new Proxy Host</Button>
            </Box>
            <Box className={`box-list ${loadingHosts && proxyHosts?.length === 0 ? 'loading-screen' : ''} ${proxyHosts?.length > 0 ? '' : 'empty-table'}`}>
                <DataGrid
                    rows={proxyHosts}
                    loading={loadingHosts}
                    slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: () => <CustomNoRowsOverlay message={'No Proxy Domains available'}/>
                    }}
                    className="box-list-table"
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 5},
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
                {proxyHostDialogOpen.show &&
                    <ProxyHostDialog
                        onClose={handleProxyHostDialogClose}
                        onSave={saveProxyHost}
                        host={proxyHostDialogOpen.host}
                    />
                }
                {deleteDialogOpen?.show &&
                    <DeleteDialog
                        onClose={() => handleDeleteClose()}
                        message={`Are you sure you want to delete the Proxy Host: ${deleteDialogOpen?.host?.host} (${deleteDialogOpen?.host?.domain?.domain})?`}
                        onDelete={() => handleDeleteHost(deleteDialogOpen?.host)}
                    />
                }
            </Box>
        </Container>
    );
}

export default ProxyHosts;