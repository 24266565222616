import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Alert} from '@mui/material';
import {useSnackbar} from "notistack";
import * as yup from "yup";
import {useFormik} from "formik";
import {AlertTitle} from "@mui/lab";

const ProxyDomainDialog = ({onClose, onSave, domain}) => {
    const [error, setError] = useState("");

    const {enqueueSnackbar} = useSnackbar();

    const validationSchema = yup.object({
        domain: yup
            .string()
            .required('Domain is required')
    });

    const formik = useFormik({
        initialValues: {
            id: null,
            domain: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSave(enqueueSnackbar, values, () => {
                onClose()
            }, (res) => setError('Data save failed: ' + (res?.response?.data?.error || res?.message)))
        },
        validateOnChange: !!domain?.id,
        validateOnMount: false
    });

    useEffect(() => {
        if (domain) {
            formik.setValues({
                id: domain.id,
                domain: domain.domain
            })
        }
    }, []);

    return (
        <Dialog
            open={true}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>{formik.values?.id ? 'Edit' : 'Add'} Domain</DialogTitle>
            {error &&
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>
            }
            <DialogContent>
                <Box mb={2} mt={1}>
                    <TextField
                        variant="standard"
                        name="domain"
                        label="Domain"
                        value={formik.values?.domain}
                        onChange={formik.handleChange}
                        fullWidth
                        helperText={formik.errors?.domain}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProxyDomainDialog;
