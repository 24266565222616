import React, {useEffect, useState} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Alert, Box} from '@mui/material';
import {generateRsaKey, saveRsaKey} from '../../services/rsakey.service';
import {useSnackbar} from "notistack";
import * as yup from "yup";
import {useFormik} from "formik";
import {AlertTitle} from "@mui/lab";

const RsaKeyDialog = ({rsaKey, onClose}) => {
    const [error, setError] = useState('');

    const {enqueueSnackbar} = useSnackbar();

    const validationSchema = yup.object({
        key_public: yup
            .string()
            .required('Public Key name is required'),
        key_private: yup
            .string()
            .required('Private Key is required'),
    });

    const formik = useFormik({
        initialValues: {
            id: null,
            key_public: "",
            key_private: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            saveRsaKey(enqueueSnackbar, values, () => {
                onClose()
            }, (response) => setError('Data save failed: ' + (response?.response?.data?.error || response?.message)))
        },
        validateOnChange: !!rsaKey?.id,
        validateOnMount: false
    });

    const handleGenerate = () => {
        generateRsaKey(enqueueSnackbar, res => {
            formik.setValues(res)
        })
    };

    useEffect(() => {
        if (rsaKey) {
            formik.setValues({
                id: rsaKey?.id,
                key_public: rsaKey?.key_public,
                key_private: rsaKey?.key_private,
            })
        }
    }, []);

    return (
        <Dialog
            open={true}
            onClose={onClose}
            maxWidth='sm'
            fullWidth={true}
        >
            <DialogTitle>{formik.values?.id ? 'Edit' : 'Add new'} RSA Key</DialogTitle>
            {error &&
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error}
                </Alert>
            }
            <DialogContent>
                <Box mb={2} mt={1}>
                    <TextField
                        variant="standard"
                        name="key_public"
                        label="Public Key"
                        value={formik.values?.key_public}
                        onChange={formik.handleChange}
                        helperText={formik.errors?.key_public}
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={5}
                    />
                </Box>
                <Box mb={2} mt={1}>
                    <TextField
                        variant="standard"
                        name="key_private"
                        label="Private Key"
                        value={formik.values?.key_private}
                        onChange={formik.handleChange}
                        helperText={formik.errors?.key_private}
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={5}
                    />
                </Box>
                <Button onClick={handleGenerate} fullWidth>Generate</Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={formik.handleSubmit} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RsaKeyDialog;
