import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import {login} from "../../actions/auth";
import { TextField, Typography, Grid, Box, Alert, ThemeProvider, CssBaseline, Paper, Avatar } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import './LoginPage.scss'
import { LoadingButton } from "@mui/lab";
import darkTheme from './../../themes/defaulTheme'
import {useFormik} from "formik";

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            username: "",
            password: ""
        },
        onSubmit: (values) => {
            setLoading(true);

            dispatch(login(values.username, values.password))
                .then(
                    () => {
                        navigate("/");
                        window.location.href = "/";
                        setLoading(false)
                    },
                    () => {
                        setError("Username or password is incorrect");
                        setLoading(false)
                    }
                )
                .catch(() => {
                    setLoading(false);
                });
        }
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Paper elevation={10} className='paper-wrapper'>
                <Box id="login-page">
                    <Grid align='center'>
                        <Avatar className='avatar'><LockOutlinedIcon /></Avatar>
                        <h2>Sign In</h2>
                    </Grid>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <TextField
                                    required
                                    label="Username"
                                    type="text"
                                    name="username"
                                    className="input-field"
                                    value={formik.username}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    required
                                    label="Password"
                                    type="password"
                                    name="password"
                                    className="input-field"
                                    value={formik.password}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item className="submit-button">
                                <LoadingButton
                                    variant="contained"
                                    type="submit"
                                    loading={loading}
                                >
                                    Login
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" align="center">
                                    Don't have an account?{' '}
                                    <Link to="/signup" style={{ color: 'inherit' }}>
                                        Create one
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid item>
                                {error && (
                                    <Alert severity="error" sx={{ mt: 2 }}>
                                        {error}
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </ThemeProvider>
    );
};

export default LoginPage;
